<template>
  <component :is="loaded === false ? 'div' : 'div'">
    <b-card-actions
        ref="refreshCard"
        action-back
        action-save
        @back="hRedirect('apps-bookingtimes-list')"
        @save="createItem"
        no-body
        disable-loading-on-refresh
        :loaded.snyc="loaded"
    >
    </b-card-actions>

    <b-card id="add-new-bookingtime-form" @change="(val) => $emit('update: add-new-bookingtimes-list', val)">
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <b-form @submit.stop.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
          <div class="mb-2">
            <b-form-checkbox v-model="currentItem.status" value="1" name="status" class="custom-control-success">
              {{ $t("Active") }}
            </b-form-checkbox>
          </div>

          <hr />

          <b-row>
            <col md="12" xl="12" class="mb-1 mt-1 ml-1" />

            <b-form-group :label="$t('Choose a Weekday')">
              <validation-provider #default="{ errors }" :name="$t('Choose a Weekday')" rules="required">
                <b-form-select v-model="currentItem.weekday" :options="options" />
                <small class="text-danger">{{ errors[0] }}</small>
                <small v-if="Object.keys(validation).includes('weekday')" class="text-danger">{{
                $t(validation.weekday[0]) }}</small>
              </validation-provider>
            </b-form-group>
          </b-row>

          <b-row>
            <b-col md="6" xl="6">
              <b-form-group :label="$t('Start Time')">
                <validation-provider #default="{ errors }" :name="$t('Starttime')" rules="required">
                  <cleave id="starttime" v-model="currentItem.starttime" :name="$t('Starttime')" class="form-control"
                    :raw="false" :options="timeOption.starttime" placeholder="hh:mm" />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small v-if="Object.keys(validation).includes('starttime')" class="text-danger">{{
                  $t(validation.starttime[0]) }}</small>
                </validation-provider>
              </b-form-group>

            </b-col>

            <b-col md="6" xl="6">

              <b-form-group :label="$t('End Time')">
                <validation-provider #default="{ errors }" :name="$t('endtime')" rules="required">
                  <cleave id="endtime" v-model="currentItem.endtime" :name="$t('endtime')" class="form-control"
                    :raw="false" :options="timeOption.endtime" placeholder="hh:mm" />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small v-if="Object.keys(validation).includes('endtime')" class="text-danger">{{
                  $t(validation.endtime[0]) }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
    <b-card>
      <b-button variant="success" type="submit" @click.prevent="onSubmit">
        {{ $t("Save") }}
      </b-button>
    </b-card>
  </component>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BAlert,
  BToast,
  BLink,
  BCard,
  BRow,
  BCol,
  BFormCheckbox,
  BFormTextarea,
  BCardText,
  BFormDatepicker,
  BFormCheckboxGroup,
  BFormFile,
  BAvatar,
  BMedia,
  BFormTimepicker,
  BFormSelect,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Cleave from "vue-cleave-component";
import { ref, onUnmounted, mounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import formValidation from "@core/comp-functions/forms/form-validation";

import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import BCardActions from '@core/components/b-card-actions/BCardActions'
import { required, alphaNum, email } from "@validations";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BAlert,
    BToast,
    BLink,
    BCard,
    BRow,
    BCol,
    BFormCheckbox,
    BFormTextarea,
    BCardText,
    BFormDatepicker,
    BFormCheckboxGroup,
    vSelect,
    BFormFile,
    BAvatar,
    BMedia,
    BFormTimepicker,
    BFormSelect,
    Cleave,
    BCardActions,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },

  directives: {
    Ripple,
  },

  data() {
    return {
      selected: null,
      options: [
        { value: null, text: this.$i18n.t("Please select a Day") },
        { value: 1, text: this.$i18n.t("Sunday") },
        { value: 2, text: this.$i18n.t("Monday") },
        { value: 3, text: this.$i18n.t("Tuesday") },
        { value: 4, text: this.$i18n.t("Wednesday") },
        { value: 5, text: this.$i18n.t("Thursday") },
        { value: 6, text: this.$i18n.t("Friday") },
        { value: 7, text: this.$i18n.t("Saturday") },
      ],

      currentItem: {
        status: 1,
        weekday: "",
        starttime: "",
        endtime: "",
      },

      timeOption: {
        starttime: {
          time: true,
          timePattern: ["h", "m"],
        },
        endtime: {
          time: true,
          timePattern: ["h", "m"],
        },
      },
      loaded: true,
      validation: {},
      validate: false,
    };
  },

  setup(props) {
    const expertData = {
      status: "",
      weekday: "",
      starttime: "",
      endtime: "",
    };

    const toast = useToast();
  },

  mounted() {
    },

  methods: {
    createItem() {
      this.$refs.refFormObserver.validate()
          .then((success) => {
            this.validation = {}
            this.validate = false
            if (success) {
              this.helperAddItem(`/bookingtimes`, this.currentItem, this.$refs.refFormObserver, null, (response) => {
                this.$router.push(
                    {
                      name: 'apps-bookingtimes-list',
                    },
                    () => setTimeout(() => {
                      this.$store.dispatch('GET_USER_ONBOARDING')
                      this.hToast('success', 'Success', 'Item saved')
                    })
                )
              })
            } else {
              this.loaded = false
              setTimeout(() => {
                this.loaded = true
              })
            }
          })
    },

    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
      x;
    },

    resetForm() {},

    onSubmit() {
      this.createItem();
    },
  },
};
</script>
