var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.loaded === false ? 'div' : 'div',{tag:"component"},[_c('b-card-actions',{ref:"refreshCard",attrs:{"action-back":"","action-save":"","no-body":"","disable-loading-on-refresh":"","loaded":_vm.loaded},on:{"back":function($event){return _vm.hRedirect('apps-bookingtimes-list')},"save":_vm.createItem}}),_c('b-card',{attrs:{"id":"add-new-bookingtime-form"},on:{"change":function (val) { return _vm.$emit('update: add-new-bookingtimes-list', val); }}},[_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return handleSubmit(_vm.onSubmit)},"reset":function($event){$event.preventDefault();return _vm.resetForm.apply(null, arguments)}}},[_c('div',{staticClass:"mb-2"},[_c('b-form-checkbox',{staticClass:"custom-control-success",attrs:{"value":"1","name":"status"},model:{value:(_vm.currentItem.status),callback:function ($$v) {_vm.$set(_vm.currentItem, "status", $$v)},expression:"currentItem.status"}},[_vm._v(" "+_vm._s(_vm.$t("Active"))+" ")])],1),_c('hr'),_c('b-row',[_c('col',{staticClass:"mb-1 mt-1 ml-1",attrs:{"md":"12","xl":"12"}}),_c('b-form-group',{attrs:{"label":_vm.$t('Choose a Weekday')}},[_c('validation-provider',{attrs:{"name":_vm.$t('Choose a Weekday'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.options},model:{value:(_vm.currentItem.weekday),callback:function ($$v) {_vm.$set(_vm.currentItem, "weekday", $$v)},expression:"currentItem.weekday"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(Object.keys(_vm.validation).includes('weekday'))?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(_vm.validation.weekday[0])))]):_vm._e()]}}],null,true)})],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6","xl":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Start Time')}},[_c('validation-provider',{attrs:{"name":_vm.$t('Starttime'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",attrs:{"id":"starttime","name":_vm.$t('Starttime'),"raw":false,"options":_vm.timeOption.starttime,"placeholder":"hh:mm"},model:{value:(_vm.currentItem.starttime),callback:function ($$v) {_vm.$set(_vm.currentItem, "starttime", $$v)},expression:"currentItem.starttime"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(Object.keys(_vm.validation).includes('starttime'))?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(_vm.validation.starttime[0])))]):_vm._e()]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6","xl":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('End Time')}},[_c('validation-provider',{attrs:{"name":_vm.$t('endtime'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",attrs:{"id":"endtime","name":_vm.$t('endtime'),"raw":false,"options":_vm.timeOption.endtime,"placeholder":"hh:mm"},model:{value:(_vm.currentItem.endtime),callback:function ($$v) {_vm.$set(_vm.currentItem, "endtime", $$v)},expression:"currentItem.endtime"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(Object.keys(_vm.validation).includes('endtime'))?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(_vm.validation.endtime[0])))]):_vm._e()]}}],null,true)})],1)],1)],1)],1)]}}])})],1),_c('b-card',[_c('b-button',{attrs:{"variant":"success","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t("Save"))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }